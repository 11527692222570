import * as React from "react"
import Layout from "../components/layout"
const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-blue-700 ">
      <div>
        <div class="max-w-7xl  container flex justify-between mx-auto radius-md">
          <div class="container overflow-x-hidden  ">
            <div class="text-xl text-center  p-10 justify-center">
              <h1 class="text-xl text-white text-center  p-4 justify-center text-5xl	">
                Believe
              </h1>
              <div class="px-10 py-20 bg-white grid gap-10 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
